<template>
    <div class="page page-news-info">
        <div class="news-info-content">
            <div class="back">
                <span @click="$router.back()">< back</span>
            </div>
            <div class="title">{{cpd_info.title}}</div>
            <div class="tips">
                <span>{{cpd_info.author}}</span>
                <span>{{cpd_info.createTime}}</span>
            </div>
            <div class="body" v-html="cpd_info.body"></div>
        </div>
    </div>
</template>

<script>
import { news } from '../../config/news'
export default {
    name: 'NewsInfo',
    computed: {
        cpd_info() {
            return news[this.$route.params.id]
        }
    }
}
</script>

<style lang="less">
@import "../../less/main.less";
.page-news-info {
    .news-info-content {
        width: 1000px;
        margin: 24px auto;
        min-height: 600px;
        @media @mobile {
            width: 100%;
            padding: 0 16px;
        }
        .back {
            color: #ccc;
            span {
                cursor: pointer;
            }
            @media @mobile {
                padding-bottom: 16px;
            }
        }
        .title {
            font-size: 28px;
            text-align: center;
            padding-bottom: 24px;
            font-family: GothamBold;
        }
        .tips {
            display: flex;
            justify-content: space-between;
            color: #999;
            padding-bottom: 24px;
        }
        .body {
            text-align: justify;
            font-size: 16px;
            line-height: 28px;
            p {
                padding: 24px 0;
                @media @mobile {
                    padding: 12px 0;
                }
            }
            img {
                width: 100%;
            }
            a {
                color: #008900;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
